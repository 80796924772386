<template>
  <v-container grid-list-lg class="pa-0">
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      style="width: 100%">
      <v-simple-table v-if="displayMode" dense>
        <template v-slot:default>
          <tbody>
            <display-field label="Nome" :value="items.name" />
            <display-field label="Tipo" :value="$t('event.type.' + items.type)" />
            <display-field label="Procedimento" :value="$t('event.procedure.' + items.procedure)" />
            <display-field label="Criado em" :value="params.insertedAt" />
          </tbody>
        </template>
      </v-simple-table>

      <v-layout
        v-else
        row
        wrap>
        <v-flex
          sm12>
          <v-text-field
            ref="name"
            v-model="params.name"
            label="Nome"
            data-cy="eventName"
            required
            filled
            placeholder="Ex: Concilia 2018, Refis/2019"
            :rules="requiredRules"
            :disabled="isDisabled" />
        </v-flex>
        <v-flex
          sm12
          data-cy="eventType">
          <v-select
            v-model="params.type"
            label="Tipo"
            item-text="label"
            :menu-props="{contentClass:'eventType'}"
            :items="typesOptions"
            :rules="multipleRules"
            :disabled="isDisabled"
            required
            filled />
        </v-flex>
        <v-flex
          sm12
          data-cy="eventProcedure">
          <v-select
            v-model="params.procedure"
            label="Procedimento"
            item-text="label"
            item-value="value"
            :items="procedureOptions"
            :rules="multipleRules"
            :menu-props="{contentClass:'eventProcedure'}"
            :disabled="isDisabled"
            required
            filled />
        </v-flex>
        <v-flex
          sm12
          data-cy="eventProcedure">
          <v-select
            v-model="params.calculate"
            label="Calculo"
            item-text="label"
            item-value="value"
            :items="calculateOptions"
            :rules="multipleRules"
            :menu-props="{contentClass:'eventProcedure'}"
            :disabled="isDisabled"
            required
            filled />
        </v-flex>
      </v-layout>

      <div
        :class="`text-${btnPosition}`"
        class="pb-2 actions-step-fixed">
        <v-btn
          v-if="btnCancelShow"
          dark
          color="grey"
          @click.native="cancel">
          {{ btnCancelLabel }}
        </v-btn>

        <v-btn
          v-if="!hideSubmit"
          color="accent"
          data-cy="event-general-submit"
          title="Ir para o próximo passo"
          :disabled="!valid"
          @click="submit">
          {{ submitLabel }}
        </v-btn>
      </div>
    </v-form>
  </v-container>
</template>


<script>
  import eventsUiMixin from '@/mixins/events/events-ui'

  export default {
    mixins: [ eventsUiMixin ],
    props: {
      focus: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        typesOptions: [
          { value: 'credit', label: 'Crédito', disabled: false },
          { value: 'pagamento', label: 'Pagamento', disabled: true },
          { value: 'atendimento', label: 'Atendimento', disabled: true }
        ],
        procedureOptions: [
          { value: 'extra_judicial', label: 'Extra Judicial', disabled: false },
          { value: 'judicial', label: 'Judicial', disabled: true }
        ],
        calculateOptions: [
          { value: 'local', label: 'Calculo Interno', disabled: false },
          { value: 'giap', label: 'GIAP', disabled: false }
        ],
        valid: true,
        isDisabled: false,
        params: {}
      }
    },
    computed: {
      isShowButton () {
        return this.isDisabled && this.hideSubmit
      }
    },
    watch: {
      focus (val) {
        val && this.focusField()
      },
      items (val) {
        this.params = val
      }
    },
    mounted () {
      this.params = this.items
      this.isDisabled = this.disabled
    },
    methods: {
      validateCheckbox (model) {
        return model.length !== 0 || 'Campo obrigatório'
      },
      focusField () {
        this.$refs.name.focus()
      }
    }
  }
</script>
